import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './afk-guide.scss';
import { Row, Table } from 'react-bootstrap';
import { CategoryCard } from '../../../modules/cs/main/components/category-card';
import { AFKItem } from '../../../modules/afk/common/components/afk-item';

const AFKGuidesDream: React.FC = () => {
  return (
    <DashboardLayout
      className={'generic-page afk-journey afk-guide'}
      game="afk"
    >
      <ul className="breadcrumb">
        <li>
          <Link to="/afk-journey/">AFK Journey</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/afk-journey/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Dream Realm</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/afk/categories/category_dream.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>AFK Journey Dream Realm</h1>
          <h2>
            Dream Realm mode from AFK Journey explained - learn how it works,
            what bosses you face, rewards to obtain and more!
          </h2>
          <p>
            Last updated: <strong>16/Jan/2025</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content ">
        <SectionHeader title="Dedicated bosses guides - Season 3" />
        <p>
          If you're looking for dedicated boss guides, here's a shortcut to
          them.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="Dream Realm - Crazed Shellbrute"
            link="/afk-journey/guides/dream-realm-shellbrute"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_brute.webp"
                alt="Dream Realm - Crazed Shellbrute"
              />
            }
            updated
          />
          <CategoryCard
            title="Dream Realm - Plague Creeper"
            link="/afk-journey/guides/dream-realm-creeper"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_creep.webp"
                alt="Dream Realm - Plague Creeper"
              />
            }
            updated
          />
          <CategoryCard
            title="Dream Realm - Illucia"
            link="/afk-journey/guides/dream-realm-illucia"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_illucia.webp"
                alt="Dream Realm - Illucia"
              />
            }
            updated
          />
          <CategoryCard
            title="Dream Realm - Thalassa"
            link="/afk-journey/guides/dream-realm-thalassa"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_thal.webp"
                alt="Dream Realm - Thalassa"
              />
            }
            updated
          />
          <CategoryCard
            title="Crystal Beetle"
            link="/afk-journey/guides/dream-realm-crystal-beetle"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_crystal.webp"
                alt="Crystal Beetle"
              />
            }
            updated
          />
          <CategoryCard
            title="Orson"
            link="/afk-journey/guides/dream-realm-orson"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_orson.webp"
                alt="Orson"
              />
            }
            updated
          />
          <CategoryCard
            title="Lone Gaze"
            link="/afk-journey/guides/dream-realm-lone-gaze"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_lone.jpg"
                alt="Primal Lord - Lone Gaze"
              />
            }
            updated
          />
          <CategoryCard
            title="Snow Stomper"
            link="/afk-journey/guides/dream-realm-snow-stomper"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_yeti.jpg"
                alt="Snow Stomper"
              />
            }
            updated
          />
        </Row>
        <SectionHeader title="Past Bosses" />
        <p>
          Here are the bosses that were available in Season 2 (or before), but
          were retired.
        </p>
        <Row
          xs={1}
          md={2}
          lg={2}
          xxl={3}
          className="g-3 category-cards-container"
        >
          <CategoryCard
            title="King Croaker"
            link="/afk-journey/guides/dream-realm-croaker"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_croaker.jpg"
                alt="King Croaker"
              />
            }
          />
          <CategoryCard
            title="Necrodrakon"
            link="/afk-journey/guides/dream-realm-necrodrakon"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_nekro.jpg"
                alt="Necrodrakon"
              />
            }
          />
          <CategoryCard
            title="Skyclops"
            link="/afk-journey/guides/dream-realm-skyclops"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_skyclops.jpg"
                alt="Skyclops"
              />
            }
          />
          <CategoryCard
            title="Alpha Bear"
            link="/afk-journey/guides/dream-realm-alpha-bear"
            image={
              <StaticImage
                src="../../../images/afk/categories/category_bear.jpg"
                alt="Primal Lord - Alpha Bear"
              />
            }
          />
        </Row>
        <SectionHeader title="Dream Realm" />
        <StaticImage
          src="../../../images/afk/generic/beginner_17.webp"
          alt="Guides"
        />
        <p>
          Dream Realm is a <strong>boss rush</strong> mode where you compete
          against every player on your server in who will deal the most damage
          to it.
        </p>
        <p>Here's how the mode works:</p>
        <ul>
          <li>
            There are <strong>8 Dream Realm bosses</strong> in total and they
            change every day. Each boss has different skills and uses different
            mechanics, so you need to adjust the team you're using to tackle it
            efficiently. The available bosses are:
          </li>
          <ul>
            <li>Crazed Shellbrute</li>
            <li>Plague Creeper</li>
            <li>Illucia</li>
            <li>Thalassa</li>
            <li>Crystal Beetle</li>
            <li>Orson</li>
            <li>Lone Gaze</li>
            <li>Snow Stomper</li>
          </ul>
          <li>
            The more damage you deal the boss, the better your score and the
            better the rewards you will obtain,
          </li>
          <li>The rewards are split into two types:</li>
          <ul>
            <li>
              <strong> Ranking reward</strong> - they are based on the rank
              you've reached at the end of the day and include:
            </li>
            <ul>
              <li>
                <AFKItem name="Dream Fragment" /> (used in the Dream Realm store
                to buy character dupes),
              </li>
              <li>
                <AFKItem name="Tidal Essence" /> (used to upgrade Exclusive
                Weapon from level 1 to 5 and to upgrade Hero Focus skill),
              </li>
              <li>
                <AFKItem name="Temporal Essence" /> (used to upgrade Exclusive
                Weapon from level 6 to 15) and time-limited Titles and Fashion
                items.
              </li>
            </ul>
            <li>
              <strong> Damage dealt rewards</strong> - they are based on the
              percentage of boss' HP you managed to deplete. The rewards include{' '}
              <AFKItem name="Dream Fragment" />, <AFKItem name="Diamonds" /> and
              boxes with Character Equipment.
            </li>
          </ul>
          <li>
            Each boss has <strong>6 available difficulties</strong> and once you
            kill him in one, you will advance to the next which makes the fight
            harder, but also gives you more points/better rewards. The last
            difficulty is an Endless mode where the boss can't be killed anymore
            and you have to deal as much damage as possible,
          </li>
          <li>
            For killing each boss for the first time on any of the available
            difficulties you will obtain <AFKItem name="Invite Letter" />,
          </li>
          <li>
            When a boss comes back in the next cycle, you will instantly start
            at the highest difficulty you've reached - so you don't have to
            waste time on killing him again on the lower ones.
          </li>
          <li>
            Initially you're given 5 attempts a day to attack the bosses, but
            the number can be increased to 10 via Mystic Collection. The attempt
            is 'used' the moment you start the battle, and you can't restart it
            when things go badly. So make each attempt count!
          </li>
        </ul>
        <SectionHeader title="Dream Realm ranking rewards" />
        <p>
          Depending on the rank you will reach at the end of the day, you will
          receive different amount of rewards:
        </p>
        <Table striped bordered responsive className="afk-ascension">
          <thead>
            <tr>
              <th>Rank</th>
              <th>
                <AFKItem name="Dream Fragment" />
              </th>
              <th>
                <AFKItem name="Tidal Essence" />
              </th>
              <th>
                <AFKItem name="Temporal Essence" />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1-10</td>
              <td>550</td>
              <td>10</td>
              <td>4</td>
            </tr>
            <tr>
              <td>11-20</td>
              <td>450</td>
              <td>8</td>
              <td>3</td>
            </tr>
            <tr>
              <td>21-50</td>
              <td>400</td>
              <td>6</td>
              <td>2</td>
            </tr>
            <tr>
              <td>51-100</td>
              <td>350</td>
              <td>6</td>
              <td>1</td>
            </tr>
            <tr>
              <td>101-200</td>
              <td>300</td>
              <td>6</td>
              <td>0</td>
            </tr>
            <tr>
              <td>200-10%</td>
              <td>255</td>
              <td>5</td>
              <td>0</td>
            </tr>
            <tr>
              <td>10%-20%</td>
              <td>250</td>
              <td>5</td>
              <td>0</td>
            </tr>
            <tr>
              <td>20%-30%</td>
              <td>235</td>
              <td>4</td>
              <td>0</td>
            </tr>
            <tr>
              <td>30%-40%</td>
              <td>225</td>
              <td>4</td>
              <td>0</td>
            </tr>
            <tr>
              <td>40%-50%</td>
              <td>200</td>
              <td>4</td>
              <td>0</td>
            </tr>
            <tr>
              <td>50%-75%</td>
              <td>175</td>
              <td>3</td>
              <td>0</td>
            </tr>
            <tr>
              <td>75%-100%</td>
              <td>150</td>
              <td>3</td>
              <td>0</td>
            </tr>
          </tbody>
        </Table>
        <p>
          On top of the above rewards, top 1-10, 11-20 and 21-50 ranks receive
          additional cosmetic rewards that you can use for 7 days from obtaining
          them:
        </p>
        <ul>
          <li>
            <strong>Top 1-10</strong> - Dream Monarch Title + Master of
            Nightmares Cloak,
          </li>
          <li>
            <strong>Top 11-20</strong> - Dream Walker Title + Guardian of Dreams
            Cloak.
          </li>
          <li>
            <strong>Top 21-50</strong> - Dream Consciousness Cloak.
          </li>
        </ul>
        <p>Here's how the cloaks look like:</p>
        <StaticImage
          src="../../../images/afk/generic/dream_cloaks.webp"
          alt="Guides"
        />
        <SectionHeader title="Dream Realm store" />
        <p>
          Using the <AFKItem name="Dream Fragment" /> you obtain from this mode,
          you can buy copies of every <AFKItem name="A-Level" /> character
          available in the game.
        </p>
        <StaticImage
          src="../../../images/afk/generic/dream_store.webp"
          alt="Guides"
        />
        <p>
          Check out tier list to find the characters you should focus on first!
        </p>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default AFKGuidesDream;

export const Head: React.FC = () => (
  <Seo
    title="Dream Realm | AFK Journey | Prydwen Institute"
    description="Dream Realm mode from AFK Journey explained - learn how it works, what bosses you face, rewards to obtain and more!"
    game="afk"
  />
);
